// @flow
import React from 'react';
import Image from 'gatsby-image/withIEPolyfill';

import styles from './styles.module.scss';

type Props = {|
   image: any,
|};

const ProjectGalleyItem = ({ image }: Props) => (
   <Image
      imgStyle={{ transition: 'opacity 0.5s ease 0s, filter 0.3s' }}
      className={styles.item}
      fluid={image}
      objectFit='cover'
      objectPosition='50% 50%'
   />
);

export default ProjectGalleyItem;
