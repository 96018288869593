// @flow
import React from 'react';
import { graphql } from 'gatsby';
import { Provider } from '%/context';
import styles from './styles.module.scss';
import IntlProvider from '%/intlProvider';

import Header from '#/Header';
import SEO from '#/seo';
import About from './About';
import Gallery from './Gallery';
import Background from './Background';
import Nav from './Nav';
import Info from './Info';
import Slider from './Slider';

type Props = {|
   data: {
      projectsJson: {
         title: {
            cs: string,
            en: string,
         },
         description: {
            cs: string,
            en: string,
         },
         thumb: {
            childImageSharp: {
               fluid: any,
            },
         },
         gallery: Array<{
            childImageSharp: {
               fluid: any,
            },
         }>,
         mobileGallery: Array<{
            childImageSharp: {
               fluid: any,
            },
         }>,
      },
   },
   pageContext: {
      index: number,
      nextSlug: string,
      prevSlug: string,
      locale: 'cs' | 'en',
      intlLink: string,
   },
|};

export const query = graphql`
   query($slug: String!) {
      projectsJson(slug: { eq: $slug }) {
         title {
            cs
            en
         }
         description {
            cs
            en
         }
         thumb {
            childImageSharp {
               fluid(maxWidth: 1200) {
                  ...GatsbyImageSharpFluid
               }
            }
         }
         gallery {
            childImageSharp {
               fluid(maxWidth: 2000) {
                  ...GatsbyImageSharpFluid
               }
            }
         }
         mobileGallery: gallery {
            childImageSharp {
               fluid(maxWidth: 1200) {
                  ...GatsbyImageSharpFluid
               }
            }
         }
      }
   }
`;

const Project = ({ data, pageContext }: Props) => {
   const original = [...data.projectsJson.mobileGallery];
   const shifted = original.shift();
   const reordered = [...original, shifted];
   const title = data.projectsJson.title[pageContext.locale];
   const sanitizedTitle = title.replace('<br>', '');
   const description = data.projectsJson.description[pageContext.locale];
   return (
      <Provider value={{ project: true, locale: pageContext.locale }}>
         <IntlProvider>
            <>
               <SEO
                  title={sanitizedTitle}
                  lang={pageContext.locale}
                  description={description}
               />
               <Header intlLink={pageContext.intlLink} />
               <div className={styles.project}>
                  <Background
                     image={data.projectsJson.thumb.childImageSharp.fluid}
                  />
                  <div className={`${styles.content} container`}>
                     <Info
                        index={pageContext.index}
                        nextSlug={pageContext.nextSlug}
                        title={title}
                        description={description}
                        locale={pageContext.locale}
                     />
                     <Slider gallery={data.projectsJson.gallery} />
                  </div>
               </div>
               <div className={styles.additional}>
                  <About desc={description} nextSlug={pageContext.nextSlug} />
                  <Gallery gallery={reordered} />
                  <Nav
                     prevSlug={pageContext.prevSlug}
                     nextSlug={pageContext.nextSlug}
                  />
               </div>
            </>
         </IntlProvider>
      </Provider>
   );
};

export default Project;
