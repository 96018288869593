// @flow
import React from 'react';
import { injectIntl } from 'react-intl';

import Heading from '#/MainHeading';
import Label from '#/Label/Label';
import Button from '#/Button';
import Paragraph from '#/Paragraph';
import styles from './styles.module.scss';

type Props = {|
   index: number,
   title: string,
   description: string,
   nextSlug: string,
   intl: any,
   locale: 'cs' | 'en',
|};

const ProjectInfo = ({
   index,
   title,
   description,
   nextSlug,
   intl,
   locale,
}: Props) => {
   const label = locale === 'cs' ? `Projekt 0${index}` : `Project 0${index}`;
   return (
      <div className={styles.info}>
         <Label className={styles.label}>{label}</Label>
         <Heading className={styles.heading}>{title}</Heading>
         <Paragraph className={styles.text}>{description}</Paragraph>
         <Button className={styles.btn} path={nextSlug}>
            {intl.formatMessage({ id: 'project.ctaNext' })}
         </Button>
      </div>
   );
};

export default injectIntl(ProjectInfo);
