// @flow
import React from 'react';
import Image from 'gatsby-image/withIEPolyfill';

import withSlider from '&/HOC/withSlider';
import Button from '#/SliderArrow';
import styles from './styles.module.scss';

type Props = {|
   gallery: Array<{
      childImageSharp: {
         fluid: {
            src: string,
         },
      },
   }>,
   index: number,
   handler: (left: boolean) => void,
|};

const ProjectSlider = ({ gallery, index, handler }: Props) => (
   <div className={styles.wrapper}>
      <div className={styles.inner}>
         <div
            className={styles.slider}
            style={{ transform: `translateX(-${index * 100}%)` }}
         >
            {gallery.map(({ childImageSharp }) => {
               const fluid = {
                  ...childImageSharp.fluid,
                  sizes: '(max-width: 2000px) 60vw, 2000px',
               };
               return (
                  <Image
                     imgStyle={{
                        transition: 'opacity 0.5s ease 0s, filter 0.3s',
                     }}
                     key={childImageSharp.fluid.src}
                     className={styles.item}
                     fluid={fluid}
                     objectFit='cover'
                     objectPosition='50% 50%'
                  />
               );
            })}
         </div>
         <Button disabled={index === 0} handler={() => handler(false)} />
         <Button
            right
            disabled={index === gallery.length - 1}
            handler={() => handler(true)}
         />
      </div>
   </div>
);

export default withSlider(ProjectSlider);
