// @flow
import React, { Component } from 'react';

type Props = {|
   gallery: Array<{
      childImageSharp: {
         fluid: any,
      },
   }>,
|};

type State = {|
   index: number,
|};

function withSlider(WrappedComponent: any) {
   return class Wrapped extends Component<Props, State> {
      state = {
         index: 0,
      };

      _handleClick = (right: boolean) => {
         if (right) {
            this.setState(({ index }) => {
               if (index < this.props.gallery.length - 1) {
                  return {
                     index: index + 1,
                  };
               }
            });
         } else {
            this.setState(({ index }) => {
               if (index > 0) {
                  return {
                     index: index - 1,
                  };
               }
            });
         }
      };

      render() {
         return (
            <WrappedComponent
               index={this.state.index}
               handler={this._handleClick}
               {...this.props}
            />
         );
      }
   };
}

export default withSlider;
