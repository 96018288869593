// @flow
import React from 'react';
import { Link } from 'gatsby';
import { injectIntl } from 'react-intl';

import Paragraph from '#/Paragraph';
import Label from '#/Label/Label';
import styles from './styles.module.scss';

type Props = {|
   desc: string,
   nextSlug: string,
   intl: any,
|};

const ProjectAbout = ({ desc, nextSlug, intl }: Props) => (
   <div className={styles.about}>
      <Link to={nextSlug} className={styles.link}>
         <Label className={styles.cta}>
            {intl.formatMessage({ id: 'project.ctaNext' })}
         </Label>
      </Link>
      <Paragraph>{desc}</Paragraph>
   </div>
);

export default injectIntl(ProjectAbout);
