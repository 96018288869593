// @flow
import React from 'react';
import { injectIntl } from 'react-intl';

import Button from '#/Button';
import styles from './styles.module.scss';

type Props = {|
   prevSlug: string,
   nextSlug: string,
   intl: any,
|};

const ProjectNav = ({ prevSlug, nextSlug, intl }: Props) => (
   <div className={styles.nav}>
      <div className={styles.inner}>
         <Button path={prevSlug}>
            {intl.formatMessage({ id: 'project.ctaPrev' })}
         </Button>
         <Button path={nextSlug}>
            {intl.formatMessage({ id: 'project.ctaNext' })}
         </Button>
      </div>
   </div>
);

export default injectIntl(ProjectNav);
