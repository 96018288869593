// @flow
import React from 'react';
import { Link } from 'gatsby';

import styles from './styles.module.scss';

type Props = {|
   children: string,
   path: string,
   className?: string,
|};

const Button = ({ children, path, className }: Props) => (
   <Link to={path} className={`${styles.link} ${className || ''}`}>
      {children}
   </Link>
);

export default Button;
