// @flow
import React from 'react';

import withSlider from '&/HOC/withSlider';
import Arrow from '#/SliderArrow';
import styles from './styles.module.scss';
import Item from './Item';

type Props = {|
   gallery: Array<{
      childImageSharp: {
         fluid: {
            src: string,
         },
      },
   }>,
   index: number,
   handler: (left: boolean) => void,
|};

const ProjectGallery = ({ gallery, index, handler }: Props) => (
   <div className={styles.gallery}>
      <div
         className={styles.inner}
         style={{
            transform: `translateX(-${index * 100}%)`,
         }}
      >
         {gallery.map(({ childImageSharp }) => (
            <Item
               key={childImageSharp.fluid.src}
               image={childImageSharp.fluid}
            />
         ))}
      </div>
      <Arrow
         className={styles.arrowLeft}
         disabled={index === 0}
         handler={() => handler(false)}
      />
      <Arrow
         className={styles.arrowRight}
         disabled={index === gallery.length - 1}
         right
         handler={() => handler(true)}
      />
   </div>
);

export default withSlider(ProjectGallery);
