// @flow
import React from 'react';
import Image from 'gatsby-image/withIEPolyfill';

import styles from './styles.module.scss';

type Props = {|
   image: any,
|};

const ProjectBackground = ({ image }: Props) => (
   <Image
      className={styles.wrapper}
      style={{ position: 'absolute' }}
      fluid={image}
      objectFit='cover'
      objectPosition='50% 50%'
   />
);

export default ProjectBackground;
